import { Modal as MUIModal } from "@mui/material";

export const Modal = ({ open, handleClose, children }) => {
  return (
    <MUIModal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <div className="w-200 m-auto mt-24 xl:mt-16 lg:mt-32">{children}</div>
    </MUIModal>
  );
};
