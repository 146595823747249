import React from "react";
import Router from "./Router";
import { useSelector } from "react-redux";
import "./index.css";
import { Loading } from "components/Loading";
import { Toastr } from "components/Toastr";

function App() {
  const { loading, toastr } = useSelector((state) => state?.common);
  return (
    <>
      <Router />
      {loading && <Loading />}
      {toastr.open && <Toastr {...toastr} />}
    </>
  );
}

export default App;
